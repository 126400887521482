

/* style menu hover */
.ant-layout-sider-children{
  background: #DFE0E1;
  height: 100%;
  overflow: auto;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after{
  background: #000;
  color: #000
}

.ant-menu-submenu-title{
  color: #000
}
.ant-menu-item a{
  color: #000
}
/* :hover */
.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover{
  background: rgba(66,135, 245,0.5) !important;
  & > a { color: #000 !important; }
}
/* :hover */
/* :actived */
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active{
  background: #232F9D!important;
}
/* :actived */
/* :selected */
.ant-menu-item-selected a{
  color: white
}
.ant-menu-item-selected .anticon{
  color: white
}
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected{
  background: #232F9D!important;
  // & > .ant-menu-submenu-title { color: #fff; }
}

.ant-menu {
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #fff;
    i { color: #fff; }
  }
  .ant-menu-submenu-open > .ant-menu-submenu-title { color: #000; }
}
// .ant-menu > .ant-menu-submenu-active[aria-expanded="false"] > .ant-menu-submenu-title {
//   color: #fff;
// }

/* :selected */
/* :open */
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open{
  background: #fff!important;
  color: white
}
/* :open */

.ant-menu-item {
  white-space: initial;
  height: auto !important;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}


/* style menu hover */
