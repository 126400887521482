.app-subtitle {
  font-size: 1.4em;
  // margin-bottom: .5em;
  &.bold {
    font-weight: bold;
  }
}

.app-value {
  font-size: 1.2em;
}
