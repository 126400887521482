.app-btn {
  border-radius: 7px;
  // padding-left: 24px;
  // padding-right: 24px;

  &.round { border-radius: 100px; }
  &.fluid { width: 100%; }

  &.ant-btn.ant-btn-primary.bordered {
    background: transparent;
    border-color: #232f9d;
    color: #232f9d;

    &:hover {
      background: #232f9d;
      color: #fff;
    }

  }

  &.ant-btn.danger {
    background: transparent;
    border-color: #ff4d4f;
    color: #ff4d4f;

    &:hover {
      background: #ff4d4f;
      color: #fff;
    }
  }


  &.ant-btn.warning {
    background: transparent;
    border-color: #ef712b;
    color: #ef712b;

    &:hover {
      background: #ef712b;
      color: #fff;
    }
  }

  &.ant-btn.ant-btn-link{
    font-weight: 600;
    color: #232f9d;
    border-width: 0px;

    &:hover { background: transparent; }
    &.warning { color: #ef712b}
    &.danger { color: #ff4d4f}
  }

  &.ant-btn.gray{
    background: #626262;
    color: #fff;
    border-color: #626262;
  }

  &.ant-btn.ant-btn-primary {
    background: #232f9d;
    border-color: #232f9d;


  }

  &.ant-btn.ant-btn-primary[disabled] {
    background: #545ca6;
    border-color: #545ca6;
  }


}
