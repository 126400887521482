.header {
  /* background-color: white; */
  text-align: right;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #797979;
  padding: 40px 40px 30px;
}

.header_container {
  color: #EFEEEE;
  float: left;
  font-size: 24px;
  line-height: 2.5;
  /* margin-right: 40px; */
}

.logo {
  height: 100px;
  margin: 16px;
  background: url('../../global/assets/logo.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 50%;
}
.logo-collapsed {
  height: 50px;
  margin: 5px;
  background: url('../../global/assets/logo.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 50%;
}

.site-layout .site-layout-background {
  background: #fff;
}

.headerTitle{
  color: #11174E;
  font-weight: 700;
  font-size: 1.2em;
}
.headerTitle-collapsed{
  color: #11174E;
  font-size: 1em;
}
.subJudul{
  color: #5F6368
}

.ant-menu-item {

}


.bold_text {
  font-weight: bold;
  color: #EF712B;
}

@media all and (max-width: 600px) {
  .logo-collapsed{
    height: 50px;
    margin: 5px;
    background: url('../../global/assets/logo.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 50%;
  }
  .header{
    padding: 40px 20px 30px;
  }
}