.detail-pengumuman {
  .value-set {
    margin-bottom: 1.5em;
  }

  .value-set-label {
    font-size: 1.2em;
    font-weight: 600;
  }

  .value-set-value {
    font-size: 1.2em;
  }

  .category-link {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1,5;
    color: #626262;
    overflow-wrap: break-word;

    &.active{
      font-family: 'Mulish', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      color: #626262;
    }
  }

  .detail-pengumuman-thumbnail {
    position: relative;
    border-radius: 16px;
    box-shadow: 0px 4px 12px rgba(0,0,0, .15);
    height: 180px;
    background: #cccccc;
    overflow: hidden;
    line-height: 180px;
    text-align: center;

    .text {
      position: absolute;
      line-height: 1;
      bottom: 10px;
      word-wrap: break-word;
      width: 100%;
      padding: 0px 20px;
    }
  }

}

.card-video {
  .ant-modal-content {
    border-radius: 0px;
  }

  .ant-modal-body {
    height: 500px;
    padding: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .ant-modal-close {
    color: #fff;
    margin-top: -56px;
    margin-right: -56px;
  }
}
