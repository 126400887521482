.app-modal {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-body {
      padding: 30px;
    }

  }
}

.swal2-popup.swal2-modal {

  // animation: swal2-show 1s !important;
  animation-duration: .1s im !important;

  .swal2-title {
    font-size: 20px;
  }

  .swal2-content {
    font-size: 1em;
  }


  .swal2-styled  {
    min-width: 100px;
    padding: 4px;
    font-size: 1em !important;
    border-radius: 2px !important;

    &.swal2-confirm {
      background: #232F9D;
    }
    &.swal2-cancel {
      background: #626262;
    }
  }

}
