
.upload-btn.no-default, .upload-btn.no-default:hover, .upload-btn.no-default:focus, .upload-btn.no-default:active{
  width: auto;
  padding: 6px 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: #232f9d;
  border: 0px solid #232f9d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  align-items: center;
  justify-content: center;
}
