.app-card {
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  .ant-card-body {
    padding: 24px;
  }
}

// .app-card.padded .ant-card-body {
//   padding: 30px 60px;
// }
