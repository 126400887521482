
/* Upload Component */

.app-upload .ant-upload-select .ant-upload-select-text, .ant-upload{
  // width: 100%;
}

.app-upload.ant-upload .ant-upload-list-item-card-actions .anticon-delete{
  color: #ff4d4f
}

.app-upload.split-upload{
  display: flex;
  justify-content: left;
  align-content: center;
  /* justify-content: ; */
}

.app-upload.split-upload .ant-upload-select-text{
  width: auto;
  /* margin-right: 1em; */
  width: 40%;
}

.app-upload.split-upload .ant-upload-list-text {
  width: 60%;
  /* flex: 1; */
}

.app-upload.split-upload .ant-upload-list-text > div {height: 100%;}
.app-upload.split-upload .ant-upload-list-text > div > span {height: 100%; display: block;}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item {
  height: 100%;
  margin-top: 0px;
  /* padding: 5px 0px; */
}

.app-upload .ant-upload-list-text .ant-upload-list-item:hover .ant-upload-list-item-info{
  background: #ffffff;
}



.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item .ant-upload-list-item-info > span{
  /* background: #fff; */
  display: flex;
  align-items: center;
}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item-card-actions {
  /* margin-right: 5px; */
}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item-name {
  padding-left: 10px;
  /* font-weight: bold; */
  /* height: 100%; */
  /* display: block; */
}



.app-upload.split-upload .ant-upload-text-icon{
  display: none;
}

/* End Upload Component */
